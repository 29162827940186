jQuery(document).ready(function ($) {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	/*$('.datepicker').datetimepicker({
	 language: 'hu',
	 weekStart: 1,
	 autoclose: 1,
	 todayHighlight: 1,
	 startView: 2,
	 minView: 2,
	 maxView: 4,
	 format: 'yyyy-mm-dd'
	 });*/

	$("a.level").each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	var options = {
		horizontal: 0.33,
		itemNav: 'basic',
		speed: 300,
		smart: 1,
		mouseDragging: 1,
		//startAt: 2,
		//activatePageOn: 'click',
//				touchDragging: 1
		prevPage: '.lefthover',
		nextPage: '.righthover',
	};
	/*	var options = {
	 horizontal: 1,
	 itemNav: 'basic',
	 smart: 1,
	 activateOn: 'click',
	 mouseDragging: 1,
	 touchDragging: 1,
	 releaseSwing: 1,
	 startAt: 0,
	 activatePageOn: 'click',
	 speed: 300,
	 elasticBounds: 1,
	 //				touchDragging: 1
	 prevPage: '.lefthover',
	 nextPage: '.righthover',
	 };*/


	/*var options = {
	 horizontal: 1,
	 itemNav: 'basic',
	 speed: 300,
	 mouseDragging: 1,
	 touchDragging: 1
	 };*/
	$('#frame').sly(options);
	//$('#frame').rel;

	//$('.slidee').width('2280px');


	$('.kereses-gomb').on('click', function () {

		var mezo = $('.kereses-mezo').find('.mezo');
		if (mezo.css('visibility') == 'hidden') {
			mezo.css('visibility', 'visible');
			mezo.animate({"width": "185px"}, 300);
		}
		else {
			mezo.animate({"width": "0"}, 500);
			setTimeout(function () {
				mezo.css('visibility', 'hidden');
			}, 500);
		}
	});


	$(".youtube").on("click", function (e) {
		e.preventDefault();
		$("#videoModal").find(".modal-title").text($(this).data("title"));
		$("#videoModal").find(".modal-body").html($(this).data("youtube"), function () {
			$("#videoModal").modal("show");
		});
	});

	$('#videoModal').on('hidden.bs.modal', function (e) {
		$("#videoModal").find(".modal-body").html('');
	});

	$('.fancybox').fancybox({
		nextEffect: 'fade',
		prevEffect: 'fade',
		padding: 0,
		helpers: {
			overlay: {
				locked: false
			}
		}
	});

	//responsive switch váltás után reload
	$('#nezet-valto').on('click', function () {
		location.reload();
	});

});